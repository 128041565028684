-
<template>
  <div
    class="reset-password overflow-auto w-100 h-100 d-flex flex-column align-items-center"
  >
    <base-header
      :title="$t('重設密碼')"
      :has-logo="!$device.isMobile"
      :is-title-bold="true"
      :left-arrow="$device.isMobile"
      :back-on-click-left="$device.isMobile"
      :right-text="$device.isMobile ? $t('儲存') : ''"
      @on-click-right="onSubmit"
    ></base-header>
    <div v-if="$device.isMobile" class="w-100 h-100 p-3">
      <b-form @submit.prevent="onSubmit">
        <b-form-group>
          <b-form-input
            id="newPassword"
            v-model="$v.formData.newPassword.$model"
            name="newPassword"
            aria-describedby="input-newPassword-feedback"
            type="password"
            maxlength="15"
            :placeholder="$t('請輸入8-15位新密碼')"
            :state="formData.isNewPasswordValid"
            @input="newPasswordInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-newPassword-feedback"
            :state="formData.isNewPasswordValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.newPassword.required" class="error">
              {{ $t('*請輸入新密碼') }}
            </div>
            <div v-else-if="!$v.formData.newPassword.minLength" class="error">
              {{ $t('*不能少於8碼') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group>
          <b-form-input
            id="repeatPassword"
            v-model="$v.formData.repeatPassword.$model"
            name="repeatPassword"
            aria-describedby="input-repeatPassword-feedback"
            type="password"
            maxlength="15"
            :placeholder="$t('請再次輸入新密碼')"
            :state="formData.isRepeatPasswordValid"
            @input="repeatPasswordInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-repeatPassword-feedback"
            :state="formData.isRepeatPasswordValid"
            class="mt-0"
          >
            <div v-if="!$v.formData.repeatPassword.required" class="error">
              {{ $t('*請再次輸入新密碼') }}
            </div>
            <div
              v-else-if="!$v.formData.repeatPassword.sameAsNewPassword"
              class="error"
            >
              {{ $t('*兩次新密碼輸入不同') }}
            </div>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </div>
    <div
      v-else
      class="w-100 h-100 d-flex align-items-center justify-content-center"
    >
      <div
        class="web-wrapper text-center position-relative d-flex flex-column align-items-center"
      >
        <h1 class="pb-3">{{ $t('重設密碼') }}</h1>
        <b-form class="w-100" @submit.prevent="onSubmit">
          <b-form-group>
            <b-form-input
              id="newPassword"
              v-model="$v.formData.newPassword.$model"
              name="newPassword"
              aria-describedby="input-newPassword-feedback"
              type="password"
              maxlength="15"
              :placeholder="$t('請輸入8-15位新密碼')"
              :state="formData.isNewPasswordValid"
              @input="newPasswordInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-newPassword-feedback"
              :state="formData.isNewPasswordValid"
              class="mt-0"
            >
              <div v-if="!$v.formData.newPassword.required" class="error">
                {{ $t('*請輸入新密碼') }}
              </div>
              <div v-else-if="!$v.formData.newPassword.minLength" class="error">
                {{ $t('*不能少於8碼') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form-input
              id="repeatPassword"
              v-model="$v.formData.repeatPassword.$model"
              name="repeatPassword"
              aria-describedby="input-repeatPassword-feedback"
              type="password"
              maxlength="15"
              :placeholder="$t('請再次輸入新密碼')"
              :state="formData.isRepeatPasswordValid"
              @input="repeatPasswordInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-repeatPassword-feedback"
              :state="formData.isRepeatPasswordValid"
              class="mt-0"
            >
              <div v-if="!$v.formData.repeatPassword.required" class="error">
                {{ $t('*請再次輸入新密碼') }}
              </div>
              <div
                v-else-if="!$v.formData.repeatPassword.sameAsNewPassword"
                class="error"
              >
                {{ $t('*兩次新密碼輸入不同') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button
            class="submit-button w-100 p-0"
            variant="primary"
            size="lg"
            @click="onSubmit"
          >
            {{ $t('送出') }}
          </b-button>
        </b-form>
        <div
          class="previos-page position-absolute cursor-pointer d-flex align-items-center"
          @click="$router.push({name: 'forget-password'})"
        >
          <i class="icon-Chevrons-left text-24 mr-1 mt-1"></i>
          <span class="text-16">{{ $t('返回上一頁') }}</span>
        </div>
      </div>
    </div>
    <ModalSignUpResult
      v-if="isModalShow"
      :show="isModalShow"
      :has-countdonw-btn="true"
      :title="$t('重設密碼成功')"
      :content="$t('請使用新設定的密碼登入')"
      @close="closeModal"
    ></ModalSignUpResult>
    <modal-confirm
      v-model="isRedirectModalShow"
      :confirm-text="$t('知道了')"
      :hide-cancel="true"
      @confirm="goLogin"
    >
      {{ $t('此驗證信時效已到期，將幫您導回登入頁。') }}
    </modal-confirm>
  </div>
</template>

<script>
import ModalSignUpResult from '@/components/Modal/ModalSignUpResult.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import BaseHeader from '@/components/Base/BaseHeader';
import {required, sameAs, minLength} from 'vuelidate/lib/validators';

export default {
  components: {
    BaseHeader,
    ModalSignUpResult,
    ModalConfirm,
  },
  data() {
    return {
      formData: {
        newPassword: '',
        repeatPassword: '',
        isNewPasswordValid: null,
        isRepeatPasswordValid: null,
      },
      backendError: '',
      isModalShow: false,
      isRedirectModalShow: false,
      debounceSec: 5,
    };
  },
  validations: {
    formData: {
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        required,
        sameAsNewPassword: sameAs('newPassword'),
      },
    },
  },
  computed: {
    type() {
      return this.$route.query.type;
    },
    code() {
      const _code = decodeURIComponent(this.$route.query.code);
      return _code.replaceAll(' ', '+');
    },
    id() {
      return this.$route.query.id;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
  },
  methods: {
    closeModal() {
      this.isModalShow = false;
      this.$router.push({name: 'login'});
    },
    async onSubmit() {
      this.$v.$touch();
      this.formData.isNewPasswordValid = null;
      this.formData.isRepeatPasswordValid = null;
      if (this.$v.$invalid) {
        this.formData.isNewPasswordValid = this.validateNewPassword()
          ? null
          : false;
        this.formData.isRepeatPasswordValid = this.validateRepeatPassword()
          ? null
          : false;
        this.focusInput();
      } else {
        await this.updatePassword();
      }
    },
    newPasswordInputHandler() {
      this.backendError = '';
      this.formData.isNewPasswordValid = null;
    },
    repeatPasswordInputHandler() {
      this.backendError = '';
      this.formData.isRepeatPasswordValid = null;
    },
    validateNewPassword() {
      const {$error} = this.$v.formData.newPassword;
      return !$error;
    },
    validateRepeatPassword() {
      const {$error} = this.$v.formData.repeatPassword;
      return !$error;
    },
    focusInput() {
      if (!this.validateNewPassword()) {
        this.$refs.newPassword.focus();
      } else if (!this.validateRepeatPassword()) {
        this.$refs.repeatPassword.focus();
      } else {
        return false;
      }
    },
    async updatePassword() {
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.$store.dispatch('user/resetPassword', {
          password: this.formData.newPassword,
          verificationId: this.id,
          verificationCode: this.code,
          isEmail: this.type === 'email',
        });
        this.$store.commit('env/setIsLoading', false);
        this.isModalShow = true;
      } catch (error) {
        this.backendError = error.response.data.message;
        this.$store.commit('env/setIsLoading', false);
        if (
          this.backendError === 'Message is overdue' ||
          this.backendError === 'Wrong id'
        ) {
          this.isRedirectModalShow = true;
          this.redirectPage();
        }
      }
    },
    redirectPage() {
      this.debounceSec = 5;
      this.timer = setInterval(() => {
        this.debounceSec -= 1;
        if (this.debounceSec === 0) {
          clearInterval(this.timer);
          this.goLogin();
        }
      }, 1000);
    },
    goLogin() {
      this.isRedirectModalShow = false;
      this.$router.push({
        name: 'login',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  position: absolute;
  z-index: 2;
  .error {
    margin-top: 4px;
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
  }
  .success-wrapper {
    height: calc(100vh - 56px);
    i {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: $positive;
      color: #ffffff;
      font-size: 66px;
    }
    span {
      color: $font-grayscale-2;
    }
  }
  .web-wrapper {
    width: 550px;
    height: 450px;
    background: white;
    padding: 80px 100px 26px 100px;
    border-radius: 10px;
    .previos-page {
      color: $primary;
      bottom: 26px;
    }
    .submit-button {
      height: 48px;
    }
  }
}
</style>
